import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials',
    standalone: true,
})
export class InitialsPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';

        const words = value.split(' ');
        const initials = words
            .slice(0, 2)
            .map((word) => word.charAt(0).toUpperCase())
            .join('');

        return initials;
    }
}
